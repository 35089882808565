<template>
    <div class="tab-bar-item">
        <router-link :to="url + path">
            <div v-if="!isActive">
                <slot name="item-icon"></slot>
            </div>
            <div v-else>
                <slot name="item-icon-active"></slot>
            </div>
            <div :style="activeStyle">
                <slot name="item-text"></slot>
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'TabBarItem',
        props: {
            path: String,
            activeColor: {
                type: String,
                default: '#1296db'
            }
        },
        data(){
            return {
                url: "/scrm_wap",
            }
        },
        computed: {
            isActive() {
                return this.$route.path.indexOf(this.path) !== -1
            },
            activeStyle() {
                return this.isActive ? {color: this.activeColor} : {}
            }
        },
    }
</script>

<style>
    .tab-bar-item {
        flex: 1;
        text-align: center;
        height: 49px;
        color: #B6B6C0;
        font-size: 10px;
    }

    .tab-bar-item img {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 5px 0;
        vertical-align: middle;
    }
    .tab-bar-item a{
        color: #B6B6C0;
    }
    
</style>