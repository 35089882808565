<template>
    <div>
        <router-view></router-view>
        <tab-bar>
            <tab-bar-item path="/client/addClient">
                <img slot="item-icon" src="@/assets/imgs/wap/tab3.png" alt="新建客户">
                <img slot="item-icon-active" src="@/assets/imgs/wap/tabC3.png" alt="新建客户">
                <div slot="item-text">新建客户</div>
            </tab-bar-item>
            <tab-bar-item path="/home">
                <img slot="item-icon" src="@/assets/imgs/wap/baobiao1.png" alt="首页">
                <img slot="item-icon-active" src="@/assets/imgs/wap/baobiao.png" alt="首页">
                <div slot="item-text">报表</div>
            </tab-bar-item>
            <tab-bar-item path="/menu">
                <img slot="item-icon" src="@/assets/imgs/wap/tab2.png" alt="功能">
                <img slot="item-icon-active" src="@/assets/imgs/wap/tabC2.png" alt="功能">
                <div slot="item-text">功能</div>
            </tab-bar-item>
            <!-- <tab-bar-item path="/set">
                <img slot="item-icon" src="@/assets/imgs/wap/tab4.png" alt="设置">
                <img slot="item-icon-active" src="@/assets/imgs/wap/tabC4.png" alt="设置">
                <div slot="item-text">设置</div>
            </tab-bar-item> -->
        </tab-bar>

    </div>
</template>

<script>

import TabBar from '../../components/tabbar.vue'
import TabBarItem from '../../components/tabbarItem.vue'

export default {
    components: {
        TabBar,
        TabBarItem
    }
}
</script>

<style>
    .ant-calendar-picker-icon{
        z-index: inherit;
    }
</style>