<template>
    <div id="tab-bar">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'TabBar'
    }
</script>

<style scoped>
    #tab-bar {
        display: flex;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #FFFFFF;
        box-shadow: 0px 1px 1px 0px #DDDDDD;
    }
</style>